@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');

.sitename {
    font-family: 'Jost', sans-serif;
}

/* Fonts */
:root {
    --default-font: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Jost", sans-serif;
    --nav-font: "Poppins", sans-serif;
  }
  
  /* Global Colors */
  :root {
    /* Background Color - This color is applied to the background of the entire website as well as individual sections. */
    --background-color: #ffffff;
  
    /* Default Color - This is the default color used for the majority of the text content. */
    --default-color: #444444;
  
    /* Heading Color - This color is used for titles, headings and secondary elements. */
    --heading-color: #37517e;
  
    /* Accent Color - This is the main accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out. */
    --accent-color: #e6bc53;
  
    /* Contrast Color - This is a color used for text when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors. */
    --contrast-color: #ffffff;
  }
  
  /* Nav Menu Colors */
  :root {
    /* Nav Color - This is the default color of the main navmenu links. */
    --nav-color: #ffffff;
  
    /* Nav Hover Color - This color is applied to main navmenu links when they are hovered over. */
    --nav-hover-color: #e6bc53;
  
    /* Nav Dropdown Background Color - This color is used as the background for dropdown boxes that appear when hovering over primary navigation items. */
    --nav-dropdown-background-color: #ffffff;
  
    /* Nav Dropdown Color - This color is used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-color: #444444;
  
    /* Nav Dropdown Hover Color - Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
    --nav-dropdown-hover-color: #e6bc53;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
  }
  
  a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    --background-color: rgba(41, 59, 83, 0.8);
    --heading-color: #ffffff;
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 15px 0;
    transition: all 0.5s;
    z-index: 997;
}

  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 36px;
    margin-right: 8px;
  }
  
  .header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 500;
    color: var(--heading-color);
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 14px;
    padding: 8px 25px;
    margin: 0 0 0 30px;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  @media (max-width: 1200px) {
    .header .logo {
      order: 1;
    }
  
    .header .btn-getstarted {
      order: 2;
      margin: 0 15px 0 0;
      padding: 6px 15px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  /* Index Page Header
  ------------------------------*/
  .index-page .header {
    --background-color: rgba(255, 255, 255, 0);
    --heading-color: #ffffff;
    --nav-color: #ffffff;
  }
  
  /* Index Page Header on Scroll
  ------------------------------*/
  .index-page.scrolled .header {
    --background-color: rgba(40, 58, 90, 0.9);
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-color);
      padding: 18px 15px;
      font-size: 15px;
      font-family: var(--nav-font);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu li:last-child a {
      padding-right: 0;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-hover-color);
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  
    .navmenu .megamenu {
      position: static;
    }
  
    .navmenu .megamenu ul {
      margin: 0;
      padding: 10px;
      background: var(--nav-dropdown-background-color);
      box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
      position: absolute;
      top: 130%;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      display: flex;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
    }
  
    .navmenu .megamenu ul li {
      flex: 1;
    }
  
    .navmenu .megamenu ul li a,
    .navmenu .megamenu ul li:hover>a {
      padding: 10px 20px;
      font-size: 15px;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .megamenu ul li a:hover,
    .navmenu .megamenu ul li .active,
    .navmenu .megamenu ul li .active:hover {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .megamenu:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dd-box-shadow {
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }
  }
  
  /* Mobile Navigation */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-dropdown-background-color);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 90%);
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: var(--contrast-color);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    padding-bottom: 50px;
    position: relative;
  }
  
  .footer .footer-newsletter {
    background-color: color-mix(in srgb, var(--heading-color), transparent 95%);
    padding: 50px 0;
  }
  
  .footer .footer-newsletter h4 {
    font-size: 24px;
  }
  
  .footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
    padding: 6px 8px;
    position: relative;
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: 0px 2px 25px color-mix(in srgb, var(--default-color), transparent 95%);
    display: flex;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  .footer .footer-newsletter .newsletter-form:focus-within {
    border-color: var(--accent-color);
  }
  
  .footer .footer-newsletter .newsletter-form input[type=email] {
    border: 0;
    padding: 4px;
    width: 100%;
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    color: var(--default-color);
  }
  
  .footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
    outline: none;
  }
  
  .footer .footer-newsletter .newsletter-form input[type=submit] {
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: -7px -8px -7px 0;
    background: var(--accent-color);
    color: var(--contrast-color);
    transition: 0.3s;
    border-radius: 50px;
  }
  
  .footer .footer-newsletter .newsletter-form input[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .footer .footer-newsletter .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-top: 10px;
  }
  
  .footer .footer-newsletter .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: subscription-loading 1s linear infinite;
  }
  
  @keyframes subscription-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .footer .footer-top {
    padding-top: 50px;
  }
  
  .footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-links ul i {
    margin-right: 3px;
    font-size: 12px;
    line-height: 0;
    color: var(--accent-color);
  }
  
  .footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-links ul a {
    display: inline-block;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    line-height: 1;
  }
  
  .footer .footer-links ul a:hover {
    color: var(--accent-color);
  }
  
  .footer .footer-about a {
    color: var(--heading-color);
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: var(--heading-font);
  }
  
  .footer .footer-contact p {
    margin-bottom: 5px;
  }
  
  .footer .copyright {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .footer .copyright p {
    margin-bottom: 0;
  }
  
  .footer .credits {
    margin-top: 6px;
    font-size: 13px;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: var(--background-color);
    transition: all 0.6s ease-out;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1.5s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: -15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    --background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 20px 0;
    position: relative;
  }
  
  .page-title h1 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }
  
  .page-title .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
  }
  
  .page-title .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .page-title .breadcrumbs ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 88px;
    overflow: clip;
  }
  
  @media (max-width: 1199px) {
  
    section,
    .section {
      scroll-margin-top: 66px;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    position: relative;
  }
  
  .section-title h2:before {
    content: "";
    position: absolute;
    display: block;
    width: 160px;
    height: 1px;
    background: color-mix(in srgb, var(--default-color), transparent 60%);
    left: 0;
    right: 0;
    bottom: 1px;
    margin: auto;
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    --background-color: #293b53;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --contrast-color: #ffffff;
    width: 100%;
    min-height: 80vh;
    position: relative;
    padding: 120px 0 60px 0;
    display: flex;
    align-items: center;
  }
  
  .hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
  }
  
  .hero p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin: 5px 0 30px 0;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 600;
  }
  
  .hero .btn-get-started {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 12px 28px;
    border-radius: 50px;
    transition: 0.5s;
  }
  
  .hero .btn-get-started:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  .hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: var(--default-color);
    font-weight: 600;
  }
  
  .hero .btn-watch-video i {
    color: var(--contrast-color);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  .hero .btn-watch-video:hover {
    color: var(--accent-color);
  }
  
  .hero .btn-watch-video:hover i {
    color: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  .hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (max-width: 640px) {
    .hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .hero p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  
    .hero .btn-get-started,
    .hero .btn-watch-video {
      font-size: 13px;
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  /*--------------------------------------------------------------
  # Clients Section
  --------------------------------------------------------------*/
  .clients {
    --background-color: color-mix(in srgb, var(--heading-color), transparent 95%);
    padding: 12px 0;
  }
  
  .clients .swiper {
    padding: 10px 0;
  }
  
  .clients .swiper-wrapper {
    height: auto;
  }
  
  .clients .swiper-slide img {
    transition: 0.3s;
    padding: 0 10px;
  }
  
  .clients .swiper-slide img:hover {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about ul {
    list-style: none;
    padding: 0;
  }
  
  .about ul li {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .about ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
  }
  
  .about .read-more {
    color: var(--accent-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 8px 28px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--accent-color);
  }
  
  .about .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
  }
  
  .about .read-more:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .about .read-more:hover i {
    transform: translate(5px, 0);
  }
  
  /*--------------------------------------------------------------
  # Why Us Section
  --------------------------------------------------------------*/
  .why-us {
    --background-color: color-mix(in srgb, var(--heading-color), transparent 95%);
    padding: 30px 0;
  }
  
  .why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
  }
  
  .why-us .content p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .why-us .faq-container .faq-item {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px 0px color-mix(in srgb, var(--default-color), transparent 90%);
    background-color: var(--contrast-color);
    overflow: hidden;
  }
  
  .why-us .faq-container .faq-item:last-child {
    margin-bottom: 0;
  }
  
  .why-us .faq-container .faq-item h3 {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin: 0 30px 0 0;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .why-us .faq-container .faq-item h3 span {
    color: var(--accent-color);
    padding-right: 5px;
    font-weight: 600;
  }
  
  .why-us .faq-container .faq-item h3:hover {
    color: var(--accent-color);
  }
  
  .why-us .faq-container .faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  
  .why-us .faq-container .faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
  }
  
  .why-us .faq-container .faq-item .faq-icon {
    position: absolute;
    top: 22px;
    left: 20px;
    font-size: 22px;
    line-height: 0;
    transition: 0.3s;
    color: var(--accent-color);
  }
  
  .why-us .faq-container .faq-item .faq-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    line-height: 0;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .why-us .faq-container .faq-item .faq-toggle:hover {
    color: var(--accent-color);
  }
  
  .why-us .faq-container .faq-active h3 {
    color: var(--accent-color);
  }
  
  .why-us .faq-container .faq-active .faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
  }
  
  .why-us .faq-container .faq-active .faq-toggle {
    transform: rotate(90deg);
    color: var(--accent-color);
  }
  
  .why-us .why-us-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .why-us .why-us-img img {
    max-height: 70%;
  }
  
  /*--------------------------------------------------------------
  # Skills Section
  --------------------------------------------------------------*/
  .skills .content h3 {
    font-size: 2rem;
    font-weight: 700;
  }
  
  .skills .content p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .skills .content p:last-child {
    margin-bottom: 0;
  }
  
  .skills .content ul {
    list-style: none;
    padding: 0;
  }
  
  .skills .content ul li {
    padding-bottom: 10px;
  }
  
  .skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }
  
  .skills .progress .skill {
    color: var(--default-color);
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: var(--heading-font);
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  
  .skills .progress-bar-wrap {
    background: color-mix(in srgb, var(--heading-color), transparent 90%);
    height: 10px;
  }
  
  .skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: var(--heading-color);
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services {
    --background-color: color-mix(in srgb, var(--heading-color), transparent 95%);
  }
  
  .services .service-item {
    box-shadow: 0px 5px 90px 0px color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    background-color: var(--contrast-color);
    height: 100%;
  }
  
  .services .service-item .icon {
    margin-bottom: 10px;
  }
  
  .services .service-item .icon i {
    color: var(--accent-color);
    font-size: 36px;
    transition: 0.3s;
  }
  
  .services .service-item h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  .services .service-item h4 a {
    color: var(--heading-color);
    transition: ease-in-out 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .service-item:hover {
    transform: translateY(-10px);
  }
  
  .services .service-item:hover h4 a {
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action {
    --background-color: #37517e;
    --default-color: #ffffff;
    --contrast-color: #ffffff;
    padding: 120px 0;
    position: relative;
    clip-path: inset(0);
    background-image: url('../img/cta-bg.jpg');
    background-size: cover;
    background-position: center;
}
  
  .call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .call-to-action:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 35%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .call-to-action .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action h3 {
    color: var(--default-color);
    font-size: 28px;
    font-weight: 700;
  }
  
  .call-to-action p {
    color: var(--default-color);
  }
  
  .call-to-action .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid var(--contrast-color);
    color: var(--contrast-color);
  }
  
  .call-to-action .cta-btn:hover {
    background: var(--accent-color);
    border: 2px solid var(--accent-color);
  }
  

  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact {
    --background-color: color-mix(in srgb, var(--heading-color), transparent 95%);
}

.contact .info-wrap {
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
    border-top: 3px solid var(--accent-color);
    border-bottom: 3px solid var(--accent-color);
    padding: 30px;
    height: 100%;
    background-color: white; /* Adicione esta linha */
}

@media (max-width: 575px) {
    .contact .info-wrap {
        padding: 20px;
    }
}

.contact .info-item {
    margin-bottom: 40px;
}

.contact .info-item i {
    font-size: 20px;
    color: var(--accent-color);
    background: color-mix(in srgb, var(--accent-color), transparent 92%);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
}

.contact .info-item:hover i {
    background: var(--accent-color);
    color: var(--contrast-color);
}

.contact .php-email-form {
    height: 100%;
    padding: 30px;
    border-top: 3px solid var(--accent-color);
    border-bottom: 3px solid var(--accent-color);
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
    background-color: white; /* Adicione esta linha */
}

@media (max-width: 575px) {
    .contact .php-email-form {
        padding: 20px;
    }
}

.contact .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 25%);
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

  /*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
  .service-details .services-list {
    padding: 10px 30px;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    margin-bottom: 20px;
  }
  
  .service-details .services-list a {
    display: block;
    line-height: 1;
    padding: 8px 0 8px 15px;
    border-left: 3px solid color-mix(in srgb, var(--default-color), transparent 70%);
    margin: 20px 0;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    transition: 0.3s;
  }
  
  .service-details .services-list a.active {
    color: var(--heading-color);
    font-weight: 700;
    border-color: var(--accent-color);
  }
  
  .service-details .services-list a:hover {
    border-color: var(--accent-color);
  }
  
  .service-details .services-img {
    margin-bottom: 20px;
  }
  
  .service-details h3 {
    font-size: 26px;
    font-weight: 700;
  }
  
  .service-details h4 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .service-details p {
    font-size: 15px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .service-details ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .service-details ul i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .team-member {
  box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 90%);
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
  padding: 30px;
  height: 100%;
}

@media (max-width: 468px) {
  .team .team-member {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
}

.team .team-member .pic {
  overflow: hidden;
  width: 150px;
  border-radius: 50%;
  flex-shrink: 0;
}

.team .team-member .pic img {
  transition: ease-in-out 0.3s;
}

.team .team-member:hover {
  transform: translateY(-10px);
}

.team .team-member .member-info {
  padding-left: 30px;
}

@media (max-width: 468px) {
  .team .team-member .member-info {
    padding: 30px 0 0 0;
    text-align: center;
  }
}

.team .team-member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
}

.team .team-member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .team-member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: color-mix(in srgb, var(--default-color), transparent 85%);
  bottom: 0;
  left: 0;
}

@media (max-width: 468px) {
  .team .team-member span::after {
    left: calc(50% - 25px);
  }
}

.team .team-member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .team-member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

@media (max-width: 468px) {
  .team .team-member .social {
    justify-content: center;
  }
}

.team .team-member .social a {
  background: color-mix(in srgb, var(--default-color), transparent 94%);
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 36px;
  height: 36px;
}

.team .team-member .social a i {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  font-size: 16px;
  margin: 0 2px;
}

.team .team-member .social a:hover {
  background: var(--accent-color);
}

.team .team-member .social a:hover i {
  color: var(--contrast-color);
}

.team .team-member .social a+a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Portfolio Details Section
--------------------------------------------------------------*/
.portfolio { 
  --background-color: color-mix(in srgb, var(--heading-color), transparent 95%);
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 90%);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

  /*--------------------------------------------------------------
  # Loading
  --------------------------------------------------------------*/

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--heading-color);;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .swiper-slide {
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

.gallery .swiper-slide-active {
  text-align: center;
}

.gallery .swiper-slide img {
  width: 100%;
  max-width: 300px; 
  height: 300px; 
  object-fit: cover; 
  border-radius: 10px;
}

@media (max-width: 991px) {
  .gallery .swiper-slide img {
    max-width: 200px; 
    height: 200px; 
  }
}
